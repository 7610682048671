const state = {
  homeNavStatus: null
}

const mutations = {
  setHomeNavStatus (state, status) {
    state.homeNavStatus = status
  }
}
const actions = {
  updateHomeNavStatus ({ commit }, status) {
    commit('setHomeNavStatus', status)
  }
}
const getters = {
  homeNavStatus: state => state.homeNavStatus
}

export default {
  state,
  mutations,
  actions,
  getters
}
