const formData = require('form-data')
const Mailgun = require('mailgun.js')
const mailgun = new Mailgun(formData)
const mg = mailgun.client({ username: 'api', key: process.env.MAILGUN_API_KEY || '3c1cd95bcddb92d3495dd240d88091f8-d2cc48bc-c3d6e9d2' })
const DOMAIN = 'sandbox184b8379156e4edd913f47afb87550bb.mailgun.org'

const sendEmail = (details) => {
  console.log('SEND EMAILLAPI', details)
  const emailDetails = 'First Name: ' + details.user.firstName + '\nLast Name: ' + details.user.lastName + '\nEmail: ' + details.user.email + '\nPhone Number: ' + details.user.phone + '\nAddress: ' + details.user.address + '\nCity: ' + details.user.city + '\nProvince: ' + details.user.province + '\nCountry: ' + details.user.country + '\nPostal Code: ' + details.user.postalCode + '\nDomain: ' + details.domain + '\nPackage: ' + details.option.name

  return mg.messages.create(DOMAIN, {
    from: 'Simplest Form <sales@simplest.co.za>',
    to: ['tim@simplest.co.za'],
    subject: 'Simplest Form - ' + details.option.name,
    text: emailDetails
  })
}
export { sendEmail }
