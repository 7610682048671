import axios from 'axios'
const getDomainStatus = (domain) => {
  const search = domain.replace(/\s/g, '')
  console.log('API - getDomainStatus', domain, search)

  // app.get('/allow-cors', function(request, response) {
  //   response.set('Access-Control-Allow-Origin', '*');
  //   response.sendFile(__dirname + '/message.json');
  // });
  return axios.post(`https://simplest.co.za/check_domain.php?d=${search}`)
  // return axios.get(`https://coza.net.za/cgi-bin/whois.sh?Domain=${search}&Enter=Enter`, {
  //   headers: {
  //     'Access-Control-Allow-Origin': '*',
  //     'Access-Control-Allow-Methods': 'GET, POST',
  //     'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
  //   }
  // })
}

export { getDomainStatus }
