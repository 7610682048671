<template>
  <div class="fullPopup m-0" @click.self="close">
    <div class="row m-0 h-100 align-items-center justify-content-center" @click.self="close">
      <div class="col-11 col-sm-9 col-md-8 col-lg-8 col-xl-7 col-xxl-6">
        <div class="popupContainer px-4 py-3 position-relative">
          <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'FullPopup',
  methods: {
    close () {
      this.$emit('closePopup')
    }
  }
}
</script>
<style scoped>
.fullPopup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--dark-blue-color-op9);
  height: 100vh;
  width: 100vw;
  z-index: 9999;
}
.popupContainer {
  background-color: #fff;
  border-radius: 1rem;
  max-height: 80vh;
  width: 100%;
  overflow-y: auto;
}
.btn-close {
  position: absolute;
  right: 1rem;
}
.btn-close:hover {
  animation: rotate 0.1s ease;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.btn-close:focus {
  outline: none;
  box-shadow: none;
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(90deg);
  }
}
</style>
