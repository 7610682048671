import { createStore } from 'vuex'
import homeNav from './modules/homeNav'
import menu from './modules/menu'
import device from './modules/device'
import checkDomain from './modules/checkDomain'
import signup from './modules/signup'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    homeNav,
    menu,
    device,
    checkDomain,
    signup
  }
})
