import { createRouter, createWebHistory } from 'vue-router'
import Home from '../pages/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/packageoption/:id',
    name: 'PackageOption',
    component: () => import('../pages/PackageOption.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/signup/:id',
    name: 'SignUp',
    component: () => import('../pages/SignUp.vue')
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import('../pages/Success.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../pages/Terms.vue')
  },
  {
    path: '/privacypolicy',
    name: 'Privacy',
    component: () => import('../pages/Privacy.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
