<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.97 123.2" class="inititalLogo" :class="{slideRotate: show === 1}" :style="{maxHeight: theLogoHeight + 'px'}">
      <g id="Layer_1" data-name="Layer 1">
        <g id="Group_1" data-name="Group 1">
          <g id="Rounded_Rectangle_1" data-name="Rounded Rectangle 1">
            <path class="cls-1-1" d="M118.91,97.64,108.17,8.34A9.47,9.47,0,0,0,97.64.07L8.34,10.81A9.46,9.46,0,0,0,.07,21.34l10.74,89.3a9.46,9.46,0,0,0,10.53,8.27l89.3-10.74A9.48,9.48,0,0,0,118.91,97.64Z"/>
          </g>
          <g id="S1">
            <text class="cls-2-1" transform="translate(30.78 101.31) rotate(-6.86)">S</text>
          </g>
        </g>
      </g>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 344.65 123.2" class="fullLogo" :class="{showOpacity: show !== 1}"  :style="{maxHeight: theFullLogoHeight + 'px'}">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Group_2" data-name="Group 2">
          <g id="Rounded_Rectangle_2" data-name="Rounded Rectangle 2">
            <path class="cls-1-2" d="M118.91,97.64,108.17,8.34A9.47,9.47,0,0,0,97.64.07L8.34,10.81A9.46,9.46,0,0,0,.07,21.34l10.74,89.3a9.46,9.46,0,0,0,10.53,8.27l89.3-10.74A9.48,9.48,0,0,0,118.91,97.64Z"/>
          </g>
          <g id="S2">
            <text class="cls-2-2" transform="translate(30.78 101.31) rotate(-6.86)">S</text>
          </g>
        </g>
        <g id="Group_2-1" data-name="Group 2-1">
          <g id="implest">
            <text class="cls-3-2" transform="translate(94.99 93.61)">implest</text>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  props: ['size', 'color'],
  data () {
    return {
      show: 1,
      theFullLogoHeight: null,
      theLogoHeight: null
    }
  },
  async mounted () {
    await window.addEventListener('resize', this.onResize)
    await this.setLogoHeights()
    this.animate()
  },
  unmounted () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    setLogoHeights () {
      const theFullLogo = this.$el.querySelector('#Layer_2')
      this.theFullLogoHeight = theFullLogo.getBoundingClientRect().bottom - theFullLogo.getBoundingClientRect().top
      this.theLogoHeight = this.theFullLogoHeight
    },
    animate () {
      this.show = 1
      setTimeout(() => {
        this.show = 2
      }, 1050)
    },
    onResize () {
      this.setLogoHeights()
    }
  }
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
  svg {
    height: inherit;
    max-width: 100%;
    /* position: absolute; */
  }
  .inititalLogo {
    position: absolute;
    left: 0;
    z-index: -1;
  }
  .inititalLogo.slideRotate {
    position: absolute;
    left: 0;
    z-index: -1;
    animation: slideRotate-in;
    animation-duration: 1s;
    animation-timing-function: ease;
  }
  @keyframes slideRotate-in {
    0% {
      position: fixed;
      left: -100%;
      transform: rotate(-40deg);
    }
    5% {
      position: fixed;
      left: -50%;
    }
    30% {
      position: fixed;
      left: 0;
    }
    100% {
      transform: rotate(0deg);
    }
  }
  .fullLogo {
    opacity: 0;
  }
  .fullLogo.showOpacity {
    opacity: 1;
    animation: fadeIn;
    animation-duration: 0.5s;
    animation-timing-function: ease;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  .cls-1-1 {
    fill:#b8c735;
    fill-rule:evenodd;
  }
  .cls-2-1 {
    font-size:104.56px;
    fill:#fff;
    font-family:ArialRoundedMTBold, Arial Rounded MT Bold;
  }
  .cls-1-2 {
    fill:#b8c735;
    fill-rule:evenodd;
  }
  .cls-2-2 {
    font-size:104.56px;
    font-family: ArialRoundedMTBold, Arial Rounded MT Bold;
  }
  .cls-2-2, .cls-3-2 {
    fill:#fff;
  }
  .cls-3-2 {
    font-size:88.78px;
    font-family:Homizio;
  }
</style>
