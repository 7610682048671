<template>
  <div class="row mx-0 py-4 blue_bg white_font">
    <div class="col-12">
      <div class="container">
        <div class="row justify-content-start align-items-center">
          <div class="col-auto">
            <router-link to="/">
              <LogoIcon size="big_logo" />
            </router-link>
          </div>
          <div class="col">
            <div class="row mb-2">
              <div class="col-auto fw-bold fs-5">
                Contact Us
              </div>
            </div>
            <div class="row">
              <div class="col-12 ">
                <a href="mailto:info@simplest.co.za" target="_blank">info@simplest.co.za</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoIcon from './LogoIcon.vue'

export default {
  components: { LogoIcon },
  name: 'Footer'
}
</script>
<style scoped>
a {
  color: #fff;
  text-decoration: none;
}
a:hover {
  color: rgba(255, 255, 255,  0.7);
}
.darkdark_bg {
  background-color: var(--darkdark-blue-color);
}
.smallFont {
  font-size: 0.8rem;
}
</style>
