<template>
  <div class="mobileMenuHolder" @click.self="closeMenu">
    <div class="mobileMenu">
      <div class="row justify-content-center align-items-center m-0">
        <div class="col-8">
          <TheMenuItems />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import TheMenuItems from './TheMenuItems.vue'
export default {
  components: { TheMenuItems },
  name: 'MobileMenu',
  methods: {
    ...mapActions([
      'updateMenuStatus'
    ]),
    closeMenu () {
      this.updateMenuStatus(false)
    }
  }
}
</script>
<style scoped>
.mobileMenuHolder {
  height: 100vh;
  width: 100vw;
  z-index: 9;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  /* background-color: var(--dark-blue-color-op9); */
  padding-top: 100px;
}
.mobileMenu {
  height: 100vh;
  width: 80vw;
  z-index: 10;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  background-color: var(--dark-blue-color);
  padding-top: 100px;
}
</style>
