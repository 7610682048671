<template>
  <div class="home">
    <div class="row m-0 vh-100 bgImage">
      <div class="col-12 h-100">
        <transition name="slideUp">
        <HomeNav class="fixed-top opacity-0" id="theNav" v-if="homeNavStatus" :class="{'opacity-100': showNav}" @scrollTo = scrollTo />
        </transition>
        <div class="row m-0 justify-content-center align-items-end h-50">
          <div class="col-12 h-50 text-center" id="theLogo">
            <LogoAnimation class="homeLogo" :style="{height: logoHeight + 'px'}"/>
          </div>
        </div>
        <div class="row m-0 justify-content-center align-items-end h-50" v-if="showNav">
          <div class="col-12 text-center tagline pb-5 mb-5">
            The simplest way to get your brand on the internet
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 white_bg" ref="about">
      <div class="col-12 py-5">
        <div class="container">
          <div class="row">
            <div class="col-md-5 text-md-end text-center mb-3">
              <div class="row h-100 justify-content-md-end justify-content-center fw-bold number_font align-items-start blue_font">
                <div class="col-auto">1</div>
                <div class="col-auto mt-4">2</div>
                <div class="col-auto">3</div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 text-center fw-bold">
              Build your website with Simplest today.<br/>No experience or technical skills required.<br/>Choose one of our 3 simplest solutions<br/>and you'll be up and running in no time.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0" ref="options">
      <div class="col-12 py-5">
        <div class="container mb-3 ">
          <div class="row mb-3">
            <div class="col-12 fs-3 fw-bold white_font">
              Simplest Solutions:
            </div>
          </div>
          <PackageOptions />
        </div>
      </div>
    </div>
    <div class="row mx-0 blockBgImage">
      <div class="col-12 px-0 py-5 my-5">
        <div class="row mx-0 white_bg_op">
          <div class="col-12 px-0 py-5">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-auto position-relative ">
                  <div class="position-absolute translate-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" class="bi bi-quote" viewBox="0 0 16 16">
                      <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"/>
                    </svg>
                  </div>
                  <div class="text-center fw-bold fs-5">
                    With absolutely no experience in web development,<br/>we set up our website through Simplest. It was inexpensive,<br/>painless and just a treat working with Simplest. Now we have<br/>hundreds of visitors per day, and are making a bit<br/>of a profit from the advertising on our site!
                  </div>
                  <!-- <div class="position-absolute top-100 start-100 translate-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-quote rotate180" viewBox="0 0 16 16">
                      <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"/>
                    </svg>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LogoAnimation from '../components/LogoAnimation.vue'
import HomeNav from '../components/HomeNav.vue'
import PackageOptions from '../views/PackageOptions.vue'
// @ is an alias to /src

export default {
  components: { LogoAnimation, HomeNav, PackageOptions },
  name: 'Home',
  data () {
    return {
      logoOffset: null,
      logoInitialHeight: null,
      logoHeight: null,
      theNavPos: null,
      showNav: false
    }
  },
  async mounted () {
    await window.scrollTo(0, 0)
    await window.addEventListener('scroll', this.handleScroll)
    await window.addEventListener('resize', this.onResize)
    await this.updateHomeNavStatus(true)
    await this.setLogo()
    this.showNav = false
    setTimeout(() => {
      this.showNav = true
    }, 2000)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    ...mapGetters([
      'homeNavStatus',
      'isMobile'
    ])
  },
  methods: {
    ...mapActions([
      'updateHomeNavStatus'
    ]),
    setLogo () {
      const theLogo = this.$el.querySelector('#theLogo')
      const theNav = this.$el.querySelector('#theNav')
      this.theNavPos = theNav.getBoundingClientRect().bottom
      this.logoOffset = theLogo.getBoundingClientRect().top
      this.logoInitialHeight = theLogo.getBoundingClientRect().bottom - theLogo.getBoundingClientRect().top
    },
    handleScroll () {
      const theLogo = this.$el.querySelector('#theLogo')
      if (this.isMobile) {
        if ((theLogo.getBoundingClientRect().top / this.logoOffset) * this.logoInitialHeight > 40) {
          this.logoHeight = (theLogo.getBoundingClientRect().top / this.logoOffset) * this.logoInitialHeight
        } else {
          this.logoHeight = 40
        }
      } else {
        if ((theLogo.getBoundingClientRect().top / this.logoOffset) * this.logoInitialHeight > 100) {
          this.logoHeight = (theLogo.getBoundingClientRect().top / this.logoOffset) * this.logoInitialHeight
        } else {
          this.logoHeight = 100
        }
      }

      if (theLogo.getBoundingClientRect().top > (this.theNavPos + 75)) {
        this.updateHomeNavStatus(true)
      } else {
        this.updateHomeNavStatus(false)
      }
    },
    onResize () {
      this.setLogo()
    },
    scrollTo (val) {
      var element = this.$refs[val]
      var top = element.offsetTop
      window.scrollTo(0, top)
    }
  }
}
</script>
<style scoped>
.home {
  margin-top: -100px;
}
.bgImage {
  background-image: url(../assets/images/banner-main-c.jpg);
  background-size: cover;
  background-blend-mode: multiply;
  background-color: rgba(0,  0, 0, 0.4);
  background-position: center center;
}
.homeLogo {
  position: absolute;
  left: 50%;
  height: 25vh;
  transform: translateX(-50%);
}
#theNav.opacity-100 {
  animation: fadeIn;
  animation-duration: 0.4s;
  animation-timing-function: ease;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.slideUp-enter-active,
.slideUp-leave-active {
  transition: all 0.5s ease;
}

.slideUp-enter-from,
.slideUp-leave-to {
  transform: translateY(-100%);
}
.tagline {
  letter-spacing: 3px;
  font-size: 1.5rem;
  color: #fff;
}
.number_font {
  font-size: 5rem;
  line-height: 1;
}

.blockBgImage {
  background-image: url(../assets/images/blockpic.jpg);
  background-size: cover;
  background-blend-mode: multiply;
  background-color: rgba(0,  0, 0, 0.6);
  background-position: center center;
  /* background-attachment: fixed; */
}
.white_bg_op {
  background-color: rgba(255, 255, 255, 0.5);
}
.rotate180 {
  transform: rotate(180deg)
}
@media (min-width: 576px) {
  .bgImage {
    background-image: url(../assets/images/banner-main-c.jpg);
    background-size: cover;
    background-blend-mode: multiply;
    background-color: rgba(0,  0, 0, 0.4);
    background-position: center center;
    background-attachment: fixed;
  }
}
</style>
