<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.97 123.2">
      <g id="Layer_2Icon" data-name="Layer 2">
        <g id="Group_1Icon" data-name="Group 1">
          <g id="Rounded_Rectangle_3Icon" data-name="Rounded Rectangle 3">
            <path class="cls-1" d="M118.91,97.64,108.17,8.34A9.47,9.47,0,0,0,97.64.07L8.34,10.81A9.46,9.46,0,0,0,.07,21.34l10.74,89.3a9.46,9.46,0,0,0,10.53,8.27l89.3-10.74A9.48,9.48,0,0,0,118.91,97.64Z"/>
          </g>
          <g id="SIcon">
            <text class="cls-2" transform="translate(30.78 101.31) rotate(-6.86)">S</text>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LogoIcon',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
  svg {
    height: inherit;
  }
  .cls-1{fill:#b8c735;fill-rule:evenodd;}.cls-2{font-size:104.56px;fill:#fff;font-family:ArialRoundedMTBold, Arial Rounded MT Bold;}
</style>
