import { getDomainStatus } from '@/api/domainCheck'

const actions = {
  async checkDomainStatus ({ commit }, domain) {
    console.log('checkDomainStatus', domain)
    return await getDomainStatus(domain)
  }
}

export default {
  actions
}
