<template>
  <div class="row mx-0 justify-content-center">
    <div class="col-auto py-2 px-1 p-md-3">
      <div @click="scrollTo('about')" class="nav-link">
        About
      </div>
    </div>
    <div class="col-auto py-2 px-1 p-md-3">
      <div @click="scrollTo('options')" class="nav-link">
        Solutions
      </div>
    </div>
    <div class="col-auto py-2 px-1 p-md-3">
      <a href="mailto:info@simplest.co.za" target="_blank" class="nav-link">
        Contact
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeNav',
  methods: {
    scrollTo (val) {
      this.$emit('scrollTo', val)
    }
  }
}
</script>
<style scoped>
.nav-link, a, a:visited, a:focus {
  color: #fff;
  cursor: pointer;
}
.nav-link:hover, a:hover {
  color: rgba(255, 255, 255,  0.7);
}
</style>
