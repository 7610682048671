<template>
  <div class="row mx-0 py-1 justify-content-center darkdark_bg smallFont">
    <div class="col-auto">
      <router-link to="/terms" class="nav-link">
        Terms & Conditions
      </router-link>
    </div>
    <div class="col-auto">
      <router-link to="/privacypolicy" class="nav-link">
        Privacy Policy
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Bottom Footer'
}
</script>
<style scoped>
a, a:visited {
  color: #fff;
}
a:hover {
  color: rgba(255, 255, 255,  0.7);
}
.darkdark_bg {
  background-color: var(--darkdark-blue-color);
}
.smallFont {
  font-size: 0.8rem;
}
</style>
