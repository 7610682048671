<template>
  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-xl-4 g-4">
    <div class="col" v-for="(item, key) in packages" :key="key">
      <div class="card h-100 blue_bg border-0 white_font">
        <div class="card-body">
          <div class="row mx-0 justify-content-center">
            <div class="col-12 text-center image_holder">
              <div class="row h-100 align-items-end">
                <div class="col-12">
                  <img :src="require('../assets/images/' + item.image + '.png')" class="img-fluid" :class="'image_height' + key"/>
                </div>
              </div>
            </div>
            <div class="col-12 text-center fs-3 fw-bold my-3">
              {{item.name}}
            </div>
            <div class="col-10">
              <div class="row">
                <div class="col-12 mb-2" v-for="(feature, index) in item.features" :key="index">
                  <li>
                    {{feature}}
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer border-0">
          <div class="row mx-0 justify-content-center">
            <div class="col-12">
              <div class="row">
                <div class="col-12 text-center my-3" v-if="item.price.annual">
                  <span class="fs-4 fw-bold">R {{item.price.annual}}</span> annual
                </div>
                <div class="col-12 text-center mb-3" v-if="item.price.onceOff && item.price.annual">
                  <span class="fs-4 fw-bold" >
                    <span v-if="(typeof(item.price.onceOff)) !== 'string' ">R </span>
                    {{item.price.onceOff}}
                  </span> once off
                  <span v-if="(typeof(item.price.onceOff)) === 'string' "> costs</span>
                </div>
                <div class="col-12 text-center mb-3" v-if="item.price.onceOff && !item.price.annual">
                   Costs
                  <span class="fs-4 fw-bold" >
                    {{item.price.onceOff}}
                  </span>
                </div>
              </div>
            </div>
            <!-- <div class="col-12" v-else>
              <div class="row">
                <div class="col-12 text-center my-3">
                  <span class="fs-4 fw-bold">Get a Quote</span>
                </div>
              </div>
            </div> -->
            <div class="col-10 text-center my-3">
              <button class="btn btn-rounded btn-outline-white w-100 fw-bold" @click="showMore(item)">
                Find out more
              </button>
            </div>
            <div class="col-10 text-center mb-3">
              <button class="btn btn-rounded btn-white w-100 fw-bold" @click="getStarted(item.key)" v-if="item.price.onceOff && item.price.annual">
                Get started
              </button>
              <button class="btn btn-rounded btn-white w-100 fw-bold" @click="getMeeting" v-else>
                Set up a meeting
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
    <FullPopup v-if="show !== null" @closePopup="show = null">
      <div class="row">
        <div class="col-12 fs-3 blue_font mb-3">
          {{show.name}}
        </div>
        <div class="col-12 mb-3" v-for="(detail, key) in show.details" :key="key">
          <div class="row">
            <div class="col-12 fw-bold blue_font text-capitalize">
              {{key}}
            </div>
            <div class="col-12" v-html="detail">
            </div>
          </div>
        </div>
        <div class="col-12 my-3 text-center">
          <button class="btn btn-rounded btn-blue fw-bold" @click="getStarted(show.key)" v-if="show.price.onceOff && show.price.annual">
            Get started
          </button>
          <button class="btn btn-rounded btn-blue fw-bold" @click="getMeeting(show)" v-else>
            Set up a meeting
          </button>
        </div>
      </div>
    </FullPopup>
    </transition>
  </div>
</template>

<script>
import FullPopup from './FullPopup.vue'

export default {
  components: { FullPopup },
  name: 'PackageOptions',
  data () {
    return {
      packages: require('@/static/packages.json'),
      show: null
    }
  },
  methods: {
    showMore (val) {
      this.show = val
    },
    getStarted (val) {
      window.scrollTo(0, 0)
      this.$router.push('/signup/' + val)
    },
    getMeeting () {
      window.open('https://calendly.com/tim-simplest/45min', '_blank')
    }
  }
}
</script>

<style scoped>
.card.blue_bg {
  background-color: var(--lightdark-blue-color);
  box-shadow: 1px 3px 8px 1px rgba(0, 0, 0, 0.1);
}
.card-footer {
  background-color: var(--lightdark-blue-color);
}
.image_holder {
  height: 120px;
}
.image_height0 {
  max-height: 50px;
}
.image_height1 {
  max-height: 100px;
}
.image_height2 {
  max-height: 100px;
}
.image_height3 {
  max-height: 100px;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
