<template>
  <div>
    <div class="row justify-content-md-end justify-content-center text-center">
      <div class="col-md-auto p-0">
        <div class="nav-link" @click="goTo('')">
          Home
        </div>
      </div>
      <div class="col-md-auto p-0"  @mouseover="showOptions" @mouseleave="hideOptions">
        <div class="nav-link">
         Solutions
        </div>
        <div class="row subMenu text-md-start" v-if="isOpen">
          <div class="col-12 p-0 blue_bg mt-2">
            <div class="row mx-0 subMenuInner">
              <div class="col-12 pb-2 px-0" v-for="(item, key) in packages" :key="key">
                <div class="nav-link" @click="goTo('packageoption/' + item.key)">
                  {{item.name}}
                </div>
              </div>
              <!-- <div class="col-12 pb-2 px-0">
                <div class="nav-link" @click="getStarted('1')">
                  Simple
                </div>
              </div>
              <div class="col-12 px-0">
                <div class="nav-link" @click="getMeeting">
                  Custom
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'TheMenuItems',
  data () {
    return {
      packages: require('@/static/packages.json'),
      isOpen: false
    }
  },
  computed: {
    ...mapGetters([
      'menuOpen'
    ])
  },
  methods: {
    ...mapActions([
      'updateMenuStatus'
    ]),
    showOptions () {
      this.isOpen = true
    },
    hideOptions () {
      this.isOpen = false
    },
    goTo (val) {
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
      this.updateMenuStatus(false)
    },
    getStarted (val) {
      window.scrollTo(0, 0)
      this.$router.push('/signup/' + val)
    },
    getMeeting () {
      window.open('https://calendly.com/tim-simplest/45min', '_blank')
    }
  }
}
</script>
<style scoped>
.nav-link {
  color: #fff;
  cursor: pointer;
}
.nav-link:hover {
  color: rgba(255, 255, 255,  0.7);
}
.subMenu {
  position: relative;
}
.subMenuInner {
  top: 100%;
  background-color: var(--dark-blue-color-op3);
}
.subMenuInner .nav-link:hover {
  color: rgba(255, 255, 255,  1);
  background-color: var(--lightdark-blue-color);
}
@media (min-width: 576px) {
  .subMenu {
    position: absolute;
  }
}
</style>
