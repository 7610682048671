const state = {
  isMobile: false
}

const mutations = {
  setMobile (state, status) {
    state.isMobile = status
  }
}
const actions = {
  updateMobile ({ commit }, status) {
    commit('setMobile', status)
  }
}
const getters = {
  isMobile: state => state.isMobile
}

export default {
  state,
  mutations,
  actions,
  getters
}
