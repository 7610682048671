<template>
  <div>
    <transition name="slideDown">
      <TopNav :scrolled="scrolled" v-if="this.$route.name !== 'Home' || (this.$route.name === 'Home' && !homeNavStatus)" />
    </transition>
    <transition name="slideFromRight">
      <MobileMenu v-if="menuOpen" />
    </transition>
    <div class="main-content">
      <div class="row mx-0">
        <div class="col-12 px-0">
          <router-view />
        </div>
      </div>
      <Footer />
      <BottomFooter />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BottomFooter from './components/BottomFooter.vue'
import Footer from './components/Footer.vue'
import MobileMenu from './components/MobileMenu.vue'
import TopNav from './components/TopNav.vue'

export default {
  components: {
    TopNav,
    MobileMenu,
    BottomFooter,
    Footer
  },
  data () {
    return {
      scrolled: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    menuOpen () {
      if (this.menuOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'menuOpen',
      'homeNavStatus',
      'isMobile'
    ])
  },
  methods: {
    ...mapActions([
      'updateMobile',
      'updateMenuStatus'
    ]),
    handleScroll () {
      if (window.scrollY > 20) {
        this.scrolled = true
      } else {
        this.scrolled = false
      }
    },
    onResize () {
      if (window.innerWidth < 768) {
        this.updateMobile(true)
      } else {
        this.updateMobile(false)
        this.updateMenuStatus(false)
      }
    }
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}
.main-content {
  padding-top: 100px;
}
.slideDown-enter-active,
.slideDown-leave-active {
  transition: all 0.5s ease;
}

.slideDown-enter-from,
.slideDown-leave-to {
  transform: translateY(-100%);
}
.slideFromRight-enter-active,
.slideFromRight-leave-active {
  transition: all 0.5s ease;
}

.slideFromRight-enter-from,
.slideFromRight-leave-to {
  transform: translateX(100%);
}
</style>
