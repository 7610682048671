const state = {
  menuOpen: false
}

const mutations = {
  setMenuStatus (state, status) {
    state.menuOpen = status
  }
}
const actions = {
  updateMenuStatus ({ commit }, status) {
    commit('setMenuStatus', status)
  }
}
const getters = {
  menuOpen: state => state.menuOpen
}

export default {
  state,
  mutations,
  actions,
  getters
}
