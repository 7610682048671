import { sendEmail } from '@/api/mailgun'

const state = {
  formDetails: null
}

const mutations = {
  setDetails (state, details) {
    state.formDetails = details
  }
}
const actions = {
  updateDetails ({ commit }, details) {
    commit('setDetails', details)
  },
  async sendFormDetails ({ dispatch }, details) {
    console.log('SEND sendFormDetails STORE', details)
    dispatch('updateDetails', details)
    const ret = await sendEmail(details)
    console.log('SEMD ret', ret)
    return ret
  }
}
const getters = {
  formDetails: state => state.formDetails
}

export default {
  state,
  mutations,
  actions,
  getters
}
