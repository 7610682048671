<template>
  <div class="row m-0 fixed-top topnav" :class="{scrolled: scrolled}">
    <div class="col-12 py-2">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-auto">
            <router-link to="/">
              <LogoFull size="header_logo" />
            </router-link>
          </div>
          <div class="col d-none d-md-block">
            <TheMenuItems />
          </div>
          <div class="col d-md-none text-end text-white">
            <span class="pointer" @click="toggleMenu">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16" :class="{rotate: menuOpen}">
                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LogoFull from './LogoFull.vue'

import TheMenuItems from './TheMenuItems.vue'

export default {
  components: { TheMenuItems, LogoFull },
  name: 'TopNav',
  props: ['scrolled'],
  computed: {
    ...mapGetters([
      'menuOpen'
    ])
  },
  methods: {
    ...mapActions([
      'updateMenuStatus'
    ]),
    toggleMenu () {
      if (this.menuOpen) {
        this.updateMenuStatus(false)
      } else {
        this.updateMenuStatus(true)
      }
    }
  }
}
</script>
<style scoped>
a {
  color: #fff;
}
a:hover {
  color: rgba(255, 255, 255,  0.7);
}

.topnav.scrolled {
  height: inherit;
}
.topnav {
  height: 100px;
  background-color: var(--dark-blue-color-op3);
}
.rotate {
  transform: rotate(90deg)
}

</style>
